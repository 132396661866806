import firebase from 'firebase';
import {ref, onUnmounted} from 'vue'

const db = firebase.firestore()

/****** These are CRUD functions for the allowed email domains ******/

const domainCollection = db.collection('domain')

export const createDomain = domain => {
    return domainCollection.add(domain)
}

export const getDomain = async id =>{
    const domain = await domainCollection.doc(id).get()
    return domain.exists ? domain.data() : null
}

export const updateDomain = (id, domain) =>{
    return domainCollection.doc(id).update(domain)
}

export const deleteDomain = id => {
    let delt = confirm("Confirm");
    if (delt == true) {
        domainCollection.doc(id).delete()
    } else {
        console.log('Not Deleted')
    }
    return delt
}

export const useLoadDomains = () => {
    const domains = ref([])
    const close = domainCollection.onSnapshot(snapshot => {
        domains.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return domains
}

/*********************/

/****** These are CRUD functions for promoting staff to manager role & the inverse ******/

const managerCollection = db.collection('user').where("role", "==", "mgr") //Selecting managers from the DB

//Loading all managers
export const useLoadManagers = () => {
    const manager = ref([])
    const close = managerCollection.onSnapshot(snapshot => {
        manager.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return manager
}

const staffCollection = db.collection('user').where("role", "==", "stf") //Selecting staff from the DB

//Loading all staff
export const useLoadStaff = () => {
    const staffs = ref([])
    const close = staffCollection.onSnapshot(snapshot => {
        staffs.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return staffs
}

//Promoting Staff to Manager role
export const promoteToMgr = (id) =>{
    if(id === "Select Staff"){
        alert("Please Select a Staff Member")
    }
    else{
        return db.collection('user').doc(id).update({role: "mgr"}), console.log("User Promoted to Manager");
    }
}

//Demoting User to Staff role
export const updateStaff = (id) =>{
    return db.collection('user').doc(id).update({role: "stf"}), console.log("User Updated successfully");
}

/****** These are CRUD functions for promoting staff to admin role & the inverse ******/

const adminCollection = db.collection('user').where("role", "==", "adm") //Selecting managers from the DB

//Loading all admins
export const useLoadAdmins = () => {
    const admin = ref([])
    const close = adminCollection.onSnapshot(snapshot => {
        admin.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return admin
}

const nonAdmCollection = db.collection('user').where("role", "!=", "adm") //Selecting nonAdm from the DB

//Loading all staff
export const useLoadNonAdm = () => {
    const nonAdms = ref([])
    const close = nonAdmCollection.onSnapshot(snapshot => {
        nonAdms.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return nonAdms
}

//Promoting Staff to Manager role
export const promoteToAdm = (id) =>{
    if(id === "Select Staff"){
        alert("Please Select a Staff Member")
    }
    else{
        return db.collection('user').doc(id).update({role: "adm"}), console.log("User Promoted to Admin");
    }
}

/****** These are CRUD functions for News ******/

const newsCollection = db.collection('news')

export const useLoadNews = () => {
    const news = ref([])
    const close = newsCollection.orderBy("date", "desc").onSnapshot(snapshot => {
        news.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return news
}

export const createNews = news => {
    return newsCollection.add(news)
}

export const getNews = async id =>{
    const news = await newsCollection.doc(id).get()
    return news.exists ? news.data() : null
}

export const updateNews = (id, news) =>{
    return newsCollection.doc(id).update(news)
}

export const deleteNews = id => {
    // return confirm(newsCollection.doc(id).delete())
    let delt = confirm("Confirm");
    if (delt == true) {
        newsCollection.doc(id).delete()
    } else {
        console.log('Not Deleted')
    }
    return delt
}

/****** Staff List retrieve function ******/

const empCollection = db.collection('staff').orderBy("order", "asc") //Selecting employees from the DB

export const useLoadEmp = () => {
    const emp = ref([])
    const close = empCollection.onSnapshot(snapshot => {
        emp.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return emp
}

/* This is the list of all users for the user activity and for the todo task assignment */

const usersCollection = db.collection('user') //Selecting all users from the DB

//Loading all users
export const useLoadUsers = () => {
    const users = ref([])
    const close = usersCollection.onSnapshot(snapshot => {
        users.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
    })
    onUnmounted(close)
    return users
}


/****** These are CRUD functions for Todos ******/

const todoCollection = db.collection('todo')

// This will fetch the todos for the current user which are assigned to them by them or the management(all user have access to their respective todos only)
export const useLoadTodoTo = () => {
    const todo = ref([])
    let todoToCollection = todoCollection.where("assignedToEmail", "==", localStorage.getItem("ploUserEmail"))
    if( "assignedToEmail", "==", localStorage.getItem("ploUserEmail") ){
        todoToCollection = todoToCollection.orderBy("due", "asc")
    
        const close = todoToCollection.onSnapshot(snapshot => {
            todo.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
        })
        onUnmounted(close)
    }
    return todo
}

// This will fetch the todos for the current user(only management) which are assigned by them to other users(only management staff will have access to their respective todos)
export const useLoadTodoBy = () => {
    const todo = ref([])
    const todoByCollection = todoCollection.where("assignedToEmail", "!=", localStorage.getItem("ploUserEmail"))
    const todoBy2Collection = todoByCollection.where("assignedByEmail", "==", localStorage.getItem("ploUserEmail"))
    // const todoBy3Collection = todoBy2Collection.orderBy("order", "desc")
    const close = todoBy2Collection.onSnapshot(snapshot => {
        todo.value = snapshot.docs.map(doc => ( { id: doc.id, ...doc.data() } ) )
        // console.log(todo.value);
        // var json = todo.value
        // console.log("JSON ",json);

        // var sortedKeys = Object.keys(json.t.seconds).sort(function(a,b) { return json.t.seconds[b].due < json.t.seconds[a].due });
        // console.log(sortedKeys);
    })
    onUnmounted(close)
    return todo
}

// This will fetch All todos from other users(only management can access this option)
export const useLoadTodoAll = () => {
    const todo = ref([])
    // let newArray = ref([])
    const todoAllCollection = todoCollection.orderBy("due","desc")
    // const todoAllCollection = todoCollection.where("assignedToEmail", "!=", localStorage.getItem("ploUserEmail"))
    // const todoAll2Collection = todoAllCollection.where("assignedByEmail", "==", localStorage.getItem("ploUserEmail"))
    const close = todoAllCollection.onSnapshot(snapshot => {
        todo.value = snapshot.docs.map(doc => 
            
            ( { id: doc.id, ...doc.data() } ) 
            
            )
            
        //  newArray = todo.value
        //   console.log("Todo: ", todo.value.filter(function (el) {
            // return el.assignedToEmail != localStorage.getItem("ploUserEmail") &&
                //    el.assignedByEmail === localStorage.getItem("ploUserEmail")
                //    el.num_of_beds >=2 &&
                //    el.num_of_baths >= 2.5;
        //   }));
        //   console.log("New Array: ", todo);
    })
    // var newArray = todo.value.filter(function (el) {
        // return el.assignedToEmail != localStorage.getItem("ploUserEmail") &&
            //    el.assignedByEmail === localStorage.getItem("ploUserEmail")
            //    el.num_of_beds >=2 &&
            //    el.num_of_baths >= 2.5;
    //   });
    //   console.log(todo);
    //   console.log(newArray);

    // const close = todoAllCollection.onSnapshot(snapshot => {
    //     todo.value = snapshot.docs.map(doc => 
    //          ( doc.data().assignedToEmail !== localStorage.getItem("ploUserEmail") ?  { id: doc.id, ...doc.data() } :'') 
           
        
        
    //         )}
            
    onUnmounted(close)
    return todo
}

export const createTodo = todo => {
    return todoCollection.add(todo)
}

export const deleteTodo = id => {
    let deltTodo = confirm("Confirm");
    if (deltTodo == true) {
        todoCollection.doc(id).delete()
    } else {
        console.log('Not Deleted')
    }
    return deltTodo
}

export const getTodo = async id =>{
    const todo = await todoCollection.doc(id).get()
    return todo.exists ? todo.data() : null
}

export const updateTodo = (id, todo) =>{
    return todoCollection.doc(id).update(todo)
}